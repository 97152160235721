import { React, useContext, useEffect, useState } from "react";
import FlagSelect from "./FlagSelect";
import logo from "../assets/images/logo-small.svg";
import { useNavigate } from "react-router-dom";
import PatientSelect from "./PatientSelect";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import { LanguageContext } from "../LanguageContext";
import Translation from "../language.json";
import "../assets/styles/User/Header.css";

let Header = ({ toggleNavbar }) => {
  const { language } = useContext(LanguageContext);
  let headerDictionary = Translation[language].header;
  const [selectedPatient, setSelectedPatient] = useState(null);
  let navigate = useNavigate();

  useEffect(() => {
    if (selectedPatient) {
      navigate(`/user/edit-patient/${selectedPatient.value}`);
      setSelectedPatient(null);
    }
  }, [selectedPatient, navigate]);

  return (
    <div className="header-container">
      <div className="header-left-side">
        <img
          className="header-logo"
          src={logo}
          alt="logo"
          onClick={() => navigate("/user/home")}
        />
        <p>
          <b>{headerDictionary.hello} 👋</b>
        </p>
        <MenuRoundedIcon className="header-menu-icon" onClick={toggleNavbar} />
      </div>
      <div className="header-right-side">
        <PatientSelect
          selectedPatient={selectedPatient}
          setSelectedPatient={setSelectedPatient}
          className="header-patient-select"
        />
        <FlagSelect />
      </div>
    </div>
  );
};

export default Header;
