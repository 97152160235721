import { Menu, MenuItem, Sidebar } from "react-pro-sidebar";
import { Link, useLocation } from "react-router-dom";
import home from "../assets/images/home.png";
import patients from "../assets/images/patients.png";
import treatments from "../assets/images/treatments.png";
import appointments from "../assets/images/appointments.png";
import stats from "../assets/images/statistics.png";
import expenses from "../assets/images/budget.png";
import settings from "../assets/images/settings.png";
import exit from "../assets/images/exit.png";
import { useContext, useEffect, useRef, useState } from "react";
import Translation from "../language.json";
import { LanguageContext } from "./../LanguageContext";
import { logoutFetch } from "../store/fetchs/authActions";
import { useDispatch } from "react-redux";
import "../assets/styles/User/Navbar.css";

let Navbar = ({ isOpen, toggleNavbar }) => {
  const { language } = useContext(LanguageContext);
  const navbarDictionary = Translation[language].navbar;

  const dispatch = useDispatch();
  const [collapsed, setCollapsed] = useState(true);
  const windowWidth = useRef(window.innerWidth);
  const [isMobile, setIsMobile] = useState(false);
  const [activeItem, setActiveItem] = useState("");

  let location = useLocation();
  let pathname = location.pathname;
  useEffect(() => {
    setActiveItem(pathname.split("/")[2]);
  }, [pathname]);

  useEffect(() => {
    if (windowWidth.current <= 768) setIsMobile(true);
  }, [windowWidth]);

  let handleLogout = () => {
    dispatch(logoutFetch());
  };

  return (
    <div
      style={{ height: "100%" }}
      onMouseEnter={() => {
        if (!isMobile) setCollapsed(false);
      }}
      onMouseLeave={() => {
        if (!isMobile) setCollapsed(true);
      }}
      className={`user-navbar ${isOpen ? "active" : "deactive"}`}
    >
      <Sidebar
        style={{ height: "100%" }}
        backgroundColor="white"
        collapsed={isMobile ? true : collapsed}
        collapsedWidth="100%"
        width="190px"
        transitionDuration={400}
      >
        <Menu className="navbar-menu">
          <MenuItem
            active={activeItem === "home"}
            icon={<img src={home} alt="home" height={20} />}
            component={<Link to="/user/home" />}
            onClick={toggleNavbar}
            className="navbar-item"
          >
            {navbarDictionary.home}
          </MenuItem>
          <MenuItem
            active={activeItem === "patients"}
            icon={<img src={patients} alt="patients" height={20} />}
            component={<Link to="/user/patients" />}
            onClick={toggleNavbar}
          >
            {navbarDictionary.patients}
          </MenuItem>
          <MenuItem
            active={activeItem === "treatments"}
            icon={<img src={treatments} alt="treatments" height={20} />}
            component={<Link to="/user/treatments" />}
            onClick={toggleNavbar}
          >
            {navbarDictionary.treatments}
          </MenuItem>
          <MenuItem
            active={activeItem === "appointments"}
            icon={<img src={appointments} alt="appointments" height={20} />}
            component={<Link to="/user/appointments" />}
            onClick={toggleNavbar}
          >
            {navbarDictionary.appointments}
          </MenuItem>
          <MenuItem
            active={activeItem === "statistics"}
            icon={<img src={stats} alt="stats" height={20} />}
            component={<Link to="/user/statistics" />}
            onClick={toggleNavbar}
          >
            {navbarDictionary.statistics}
          </MenuItem>
          <MenuItem
            active={activeItem === "expenses"}
            icon={<img src={expenses} alt="expenses" height={20} />}
            component={<Link to="/user/expenses" />}
            onClick={toggleNavbar}
          >
            Xərclər
          </MenuItem>
          <MenuItem
            active={activeItem === "profile"}
            icon={<img src={settings} alt="settings" height={20} />}
            component={<Link to="/user/profile" />}
            onClick={toggleNavbar}
          >
            {navbarDictionary.profile}
          </MenuItem>
          <MenuItem
            icon={<img src={exit} alt="exit" height={20} />}
            onClick={() => {
              handleLogout();
              toggleNavbar();
            }}
          >
            {navbarDictionary.logout}
          </MenuItem>
        </Menu>
      </Sidebar>
    </div>
  );
};

export default Navbar;
