import React, { useState, useEffect } from "react";
import { Button, Box } from "@mui/material";
import ExpenseItem from "./ExpenseItem";
import fetchBaseUrl from "../../store/fetchs/fetchBaseURL.json";
import fetchWithAuth from "./../../store/fetchs/fetchWithAuth";
import { useSelector } from "react-redux";
import StatisticsPassword from "../Statistics/StatisticsPassword";

const API_BASE_URL = fetchBaseUrl.API_BASE_URL; // Replace with your actual API base URL

const Expenses = () => {
  const [expenses, setExpenses] = useState([]);
  const [editingItemId, setEditingItemId] = useState(null);
  const isAuthenticated = useSelector(
    (state) => state.statistics.isAuthenticated
  );

  useEffect(() => {
    fetchExpenses();
  }, []);

  const fetchExpenses = async () => {
    try {
      const response = await fetchWithAuth(
        `${API_BASE_URL}/api/Expense/get-expenses`
      );
      const data = await response.json();
      setExpenses(data);
    } catch (error) {
      console.error("Failed to fetch expenses:", error);
    }
  };

  const createExpense = async (name, price) => {
    try {
      const response = await fetchWithAuth(
        `${API_BASE_URL}/api/Expense/create-expense`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ name, price }),
        }
      );
      const newExpense = await response.json();
      return newExpense;
    } catch (error) {
      console.error("Failed to create expense:", error);
      return null;
    }
  };

  const updateExpense = async (id, name, price) => {
    try {
      const response = await fetchWithAuth(
        `${API_BASE_URL}/api/Expense/update-expense/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ name, price }),
        }
      );
      const updatedExpense = await response.status;
      return updatedExpense;
    } catch (error) {
      console.error("Failed to update expense:", error);
      return null;
    }
  };

  const deleteExpense = async (id) => {
    try {
      await fetchWithAuth(`${API_BASE_URL}/api/Expense/delete-expense/${id}`, {
        method: "DELETE",
      });
      setExpenses(expenses.filter((expense) => expense.id !== id));
    } catch (error) {
      console.error("Failed to delete expense:", error);
    }
  };

  const handleAdd = async () => {
    const newExpense = await createExpense("New Expense", 0);
    if (newExpense && newExpense.id) {
      setExpenses([newExpense, ...expenses]);
      setEditingItemId(newExpense.id);
    }
  };

  const handleSave = async (id, name, price) => {
    const updatedExpense = await updateExpense(id, name, price);
    if (updatedExpense === 200) {
      setEditingItemId(null);
    }
  };

  const handleEdit = (id) => {
    setEditingItemId(id);
  };

  // Function to filter expenses for a specific period
  const calculateTotalForPeriod = (startDate, endDate) => {
    return expenses
      .filter((expense) => {
        const expenseDate = new Date(expense.date);
        return expenseDate >= startDate && expenseDate <= endDate;
      })
      .reduce((total, expense) => total + expense.price, 0);
  };

  // Get the total expenses for Today, This Week, and This Month
  const today = new Date();
  const startOfToday = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  );
  const startOfWeek = new Date(today.setDate(today.getDate() - today.getDay()));
  const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

  const todayTotal = calculateTotalForPeriod(startOfToday, new Date());
  const thisWeekTotal = calculateTotalForPeriod(startOfWeek, new Date());
  const thisMonthTotal = calculateTotalForPeriod(startOfMonth, new Date());

  return (
    <>
      {isAuthenticated ? (
        <div style={{ display: "flex" }}>
          <Box
            minWidth={"50%"}
            display="flex"
            flexDirection="column"
            alignItems="center"
            p={2}
          >
            <h2>Xərclər</h2>
            <div style={{ height: "70vh", overflowY: "auto", width: "100%" }}>
              {[...expenses]
                .sort((a, b) => b.id - a.id)
                .map((expense) => (
                  <ExpenseItem
                    key={expense.id}
                    item={expense}
                    isEditing={expense.id === editingItemId}
                    onSave={handleSave}
                    onDelete={deleteExpense}
                    onEdit={handleEdit}
                  />
                ))}
            </div>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAdd}
              disabled={editingItemId !== null}
              style={{ marginTop: 16 }}
            >
              Yeni Xərc
            </Button>
          </Box>
          <div style={{ marginTop: 20, padding: "0 16px" }}>
            <h3>Cəmi</h3>
            <p>Bugün: {todayTotal.toFixed(2)}</p>
            <p>Bu həftə: {thisWeekTotal.toFixed(2)}</p>
            <p>Bu ay: {thisMonthTotal.toFixed(2)}</p>
          </div>
        </div>
      ) : (
        <StatisticsPassword />
      )}
    </>
  );
};

export default Expenses;
