import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import {
  Button,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import React, { useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { LanguageContext } from "../../LanguageContext";
import Translation from "../../language.json";
import { useNavigate } from "react-router-dom";
import "../../assets/styles/User/Profile/ChangeStatisticsPassword.css";
import { changeStatisticsPassword } from "../../store/fetchs/profileActions";

const ChangeStatisticsPassword = () => {
  const { language } = useContext(LanguageContext);
  const changePasswordDictionary = Translation[language].changePassword;

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const changeStatisticsPasswordSuccess = useSelector(
    (state) => state.profile.changeStatisticsPasswordSuccess
  );
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleChangePassword = () => {
    if (newPassword !== newPasswordConfirm) {
      toast.error(changePasswordDictionary.errorMessage.passwordMismatch);
      return;
    }
    if (newPassword.length < 6) {
      toast.error(changePasswordDictionary.errorMessage.passwordLength);
      return;
    }
    dispatch(changeStatisticsPassword(currentPassword, newPassword)).then(
      () => {
        if (changeStatisticsPasswordSuccess) {
          setCurrentPassword("");
          setNewPassword("");
          setNewPasswordConfirm("");
          toast.success(changePasswordDictionary.successMessage);
          navigate("/user/profile");
        } else {
          toast.error("Xəta,yenidən cəhd edin!");
        }
      }
    );
  };

  return (
    <div className="change-statistics-password-container user-box-shadow">
      <h2>{changePasswordDictionary.header}</h2>
      <div className="change-statistics-password-input-container">
        <div className="change-statistics-password-label-input-container">
          <label>{changePasswordDictionary.currentPasswordLabel}</label>
          <OutlinedInput
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            size="small"
            type={showPassword ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </div>
        <div className="change-statistics-password-label-input-container">
          <label>{changePasswordDictionary.newPasswordLabel}</label>
          <OutlinedInput
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            size="small"
            type={showPassword ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </div>
        <div className="change-statistics-password-label-input-container">
          <label>{changePasswordDictionary.newPasswordConfirmLabel}</label>
          <OutlinedInput
            value={newPasswordConfirm}
            onChange={(e) => setNewPasswordConfirm(e.target.value)}
            size="small"
            type={showPassword ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </div>
        <Button
          variant="outlined"
          className="change-statistics-password-save-btn"
          onClick={handleChangePassword}
          disabled={newPassword.length < 6}
        >
          {changePasswordDictionary.saveButton}
        </Button>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ChangeStatisticsPassword;
