import React, { useContext, useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  IconButton,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import emptyImage from "../../assets/images/sad_emoji.png";
import { LanguageContext } from "../../LanguageContext";
import Translation from "../../language.json";
import {
  cancelAppointment,
  fetchAppointmentById,
  markAppointmentAsFinished,
} from "../../store/fetchs/appointmentActions";
import SetAppointmentPriceModal from "../Appointments/SetAppointmentPriceModal";
import EditAppointment from "../EditAppointment/EditAppointment";
import EditIcon from "@mui/icons-material/Edit";
import "../../assets/styles/User/Home/TodayAppointments.css";

const statusColors = {
  Finished: "green",
  Pending: "orange",
  Cancelled: "red",
};

let TodayAppointments = () => {
  const { language } = useContext(LanguageContext);
  const todayAppointmentsDictionary = Translation[language].todayAppointments;
  const [openSetPrice, setOpenSetPrice] = useState(false); // State for the new modal
  const [price, setPrice] = useState(null);
  const [appointmentId, setAppointmentId] = useState(null);
  const [openEdit, setOpenEdit] = useState(false);
  const [editableAppointment, setEditableAppointment] = useState(null);
  const appointment = useSelector((state) => state.appointment.appointment);

  useEffect(() => {
    if (appointment) {
      setEditableAppointment(appointment);
    }
  }, [appointment]);
  
  const dispatch = useDispatch();

  const appointments = useSelector(
    (state) => state.appointment.appointmentsByDate
  );

  const handleCancel = (id) => {
    dispatch(cancelAppointment(id));
    window.location.reload();
  };

  const handleEdit = async (id) => {
    await dispatch(fetchAppointmentById(id));
    handleOpenEdit();
  };

  const handleOpenSetPrice = (id) => {
    setAppointmentId(id);
    setOpenSetPrice(true);
  };

  const handleSetPriceConfirm = () => {
    dispatch(markAppointmentAsFinished(appointmentId, price)).then(() => {
      window.location.reload();
      handleCloseSetPrice();
    });
  };
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);

  const handleCloseSetPrice = () => setOpenSetPrice(false);

  const refreshAppointments = () => {
    window.location.reload();
  };

  return (
    <>
      <h3 style={{ marginTop: 0 }}>{todayAppointmentsDictionary.header}</h3>
      {appointments.length === 0 ? (
        <div className="today-appointment-container">
          <img src={emptyImage} alt="no data" />
          <p>{todayAppointmentsDictionary.noData}</p>
        </div>
      ) : (
        <div style={{ height: "250px", overflowY: "auto" }}>
          <TableContainer>
            <Table aria-label="appointments table">
              <TableBody>
                {appointments.map((appointment, index) => (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{appointment.patientFullName}</TableCell>
                    <TableCell>{appointment.phoneNumber}</TableCell>
                    <TableCell>{appointment.serviceName}</TableCell>
                    <TableCell>{appointment.time}</TableCell>
                    <TableCell
                      style={{ color: statusColors[appointment.status] }}
                    >
                      {appointment.status === "Pending"
                        ? todayAppointmentsDictionary.statuses.pending
                        : appointment.status === "Finished"
                        ? todayAppointmentsDictionary.statuses.finished
                        : todayAppointmentsDictionary.statuses.cancelled}
                    </TableCell>
                    <TableCell>
                      {appointment.status === "Pending" && (
                        <>
                          <IconButton
                            color="primary"
                            onClick={() => handleOpenSetPrice(appointment.id)}
                          >
                            <CheckIcon />
                          </IconButton>
                          <IconButton
                            color="error"
                            onClick={() => handleCancel(appointment.id)}
                          >
                            <CloseIcon />
                          </IconButton>
                        </>
                      )}
                      <IconButton
                        color="default"
                        onClick={() => handleEdit(appointment.id)}
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
      <EditAppointment
        open={openEdit}
        handleClose={handleCloseEdit}
        appointment={editableAppointment}
        refreshAppointments={refreshAppointments}
      />
      <SetAppointmentPriceModal
        open={openSetPrice}
        handleClose={handleCloseSetPrice}
        handleConfirm={handleSetPriceConfirm}
        setPrice={setPrice}
      />
    </>
  );
};

export default TodayAppointments;
