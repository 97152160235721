import React, { useEffect, useState } from "react";
import StatisticsPassword from "../Statistics/StatisticsPassword"; // Import the password component
import { useDispatch, useSelector } from "react-redux";
import HospitalStatisticGeneralStatistics from "./HospitalStatisticGeneralStatistics";
import { useParams } from "react-router-dom";
import { fetchHospitalAllStatistics } from "../../store/fetchs/statisticsActions";
import HospitalPatientsComeFrom from "./HospitalPatientsComeFrom";
import "../../assets/styles/User/HospitalStatistic/HospitalStatistics.css";

let Statistics = () => {
  const { id } = useParams();

  const isAuthenticated = useSelector(
    (state) => state.statistics.isAuthenticated
  );
  const dispatch = useDispatch();
  const hospitalStatistics = useSelector(
    (state) => state.statistics.hospitalAllStatistics
  );

  const [generalStatistics, setGeneralStatistics] = useState({});
  const [appealStatistics, setAppealStatistics] = useState({});

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchHospitalAllStatistics(id));
    }
  }, [isAuthenticated, dispatch, id]);

  useEffect(() => {
    if (hospitalStatistics) {
      setGeneralStatistics({
        totalAppointments: hospitalStatistics?.totalAppointments,
        totalTreatments: hospitalStatistics?.totalTreatments,
        totalPatients: hospitalStatistics?.totalPatients,
        totalPrice: hospitalStatistics?.totalPrice,
      });
      setAppealStatistics({
        socialMedia: hospitalStatistics?.socialMedia,
        doctorReferral: hospitalStatistics?.doctorReferral,
        clinicReferral: hospitalStatistics?.clinicReferral,
        personal: hospitalStatistics?.personal,
      });
    }
  }, [hospitalStatistics]);

  return (
    <div className="user-statistics-container">
      {isAuthenticated ? (
        <>
          <h2>{hospitalStatistics?.hospitalName}</h2>
          <div className="statistics-general-statisics user-box-shadow">
            <HospitalStatisticGeneralStatistics
              generalStatistics={generalStatistics}
            />
          </div>
          <div className="statistics-patient-come-from user-box-shadow">
            <HospitalPatientsComeFrom appealStatistics={appealStatistics} />
          </div>
        </>
      ) : (
        <StatisticsPassword />
      )}
    </div>
  );
};

export default Statistics;
