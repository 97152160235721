import "../../assets/styles/Auth/AuthFooter.css";

let AuthFooter = () => {
  let year = new Date().getFullYear();
  return (
    <div className="auth-footer-container">
      <p>
        &copy;{year} PDMP . Design & Develop by {" "}
        <a target="_blank" href="https://webonly.io" rel="noreferrer">
          Webonly.io
        </a>
      </p>
    </div>
  );
};

export default AuthFooter;
