import {
  fetchHospitalsRequest,
  fetchHospitalsSuccess,
  fetchHospitalsFailure,
  createHospitalRequest,
  createHospitalSuccess,
  createHospitalFailure,
  updateHospitalRequest,
  updateHospitalSuccess,
  updateHospitalFailure,
  deleteHospitalRequest,
  deleteHospitalSuccess,
  deleteHospitalFailure,
} from "../reducers/hospitalReducer";
import fetchWithAuth from "./fetchWithAuth";
import fetchBaseUrl from "./fetchBaseURL.json";

const baseUrl = fetchBaseUrl.API_BASE_URL;

export const fetchHospitals = () => (dispatch) => {
  dispatch(fetchHospitalsRequest());
  return fetchWithAuth(`${baseUrl}/api/Hospital/get-hospitals`, {
    method: "GET",
  })
    .then((response) => response.json())
    .then((data) => {
      dispatch(fetchHospitalsSuccess(data));
    })
    .catch((err) => {
      dispatch(fetchHospitalsFailure(err.message));
    });
};

export const createHospital = (name) => (dispatch) => {
  dispatch(createHospitalRequest());
  return fetchWithAuth(`${baseUrl}/api/Hospital/create-hospital`, {
    method: "POST",
    body: JSON.stringify({ name }),
  })
    .then((response) => response.json())
    .then((data) => {
      dispatch(createHospitalSuccess(data));
      return data;
    })
    .catch((err) => {
      dispatch(createHospitalFailure(err.message));
    });
};

export const updateHospital = (id, name) => (dispatch) => {
  dispatch(updateHospitalRequest());
  return fetchWithAuth(`${baseUrl}/api/Hospital/update-hospital/${id}`, {
    method: "PUT",
    body: JSON.stringify({ name }),
  })
    .then((response) => response.text())
    .then((data) => {
      dispatch(updateHospitalSuccess(data));
    })
    .catch((err) => {
      dispatch(updateHospitalFailure(err.message));
    });
};

export const deleteHospital = (id) => (dispatch) => {
  dispatch(deleteHospitalRequest());
  return fetchWithAuth(`${baseUrl}/api/Hospital/delete-hospital/${id}`, {
    method: "DELETE",
  })
    .then((response) => response.text())
    .then((data) => {
      dispatch(deleteHospitalSuccess(id));
    })
    .catch((err) => {
      dispatch(deleteHospitalFailure(err.message));
    });
};
