import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "./authReducer";
import patientReducer from "./patientReducer";
import treatmentReducer from "./treatmentReducer";
import treatmentFormReducer from "./treatmentFormReducer";
import serviceReducer from "./serviceReducer";
import medicineReducer from "./medicineReducer";
import diagnoseReducer from "./diagnoseReducer";
import appointmentReducer from "./appointmentReducer";
import statisticsReducer from "./statisticsReducer";
import profileReducer from "./profileReducer";
import hospitalReducer from "./hospitalReducer";
import categoryReducer from "./categoryReducer";
import expenseReducer from "./expenseReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  appointment: appointmentReducer,
  patient: patientReducer,
  treatment: treatmentReducer,
  treatmentForm: treatmentFormReducer,
  service: serviceReducer,
  hospital: hospitalReducer,
  medicine: medicineReducer,
  diagnose: diagnoseReducer,
  statistics: statisticsReducer,
  profile: profileReducer,
  category: categoryReducer,
  expense: expenseReducer,
});

export default rootReducer;
