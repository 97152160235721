import React, { useState, useEffect, useContext } from "react";
import { Box, Button, IconButton } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import NewAppointment from "../AddAppointment/NewAppointment";
import MedicineSelect from "../AddTreatment/MedicineSelect";
import DiagnoseSelect from "../AddTreatment/DiagnoseSelect";
import ServiceProcedureSelect from "../AddTreatment/ServiceProcedureSelect";
import PatientSelect from "../PatientSelect";
import Translation from "../../language.json";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  fetchTreatmentById,
  updateTreatment,
} from "../../store/fetchs/treatmentActions";
import UploadFile from "../UploadFile";
import { fetchTreatmentForms } from "./../../store/fetchs/treatmentFormActions";
import { LanguageContext } from "../../LanguageContext";
import { toast } from "react-toastify";
import HospitalSelect from "../AddTreatment/HospitalSelect";
import "../../assets/styles/User/EditTreatment/EditTreatment.css";
import CategorySelect from "../AddTreatment/CategorySelect";

const EditTreatment = () => {
  const { id } = useParams();

  const { language } = useContext(LanguageContext);
  const addTreatmentDictionary = Translation[language].addTreatment;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const treatment = useSelector((state) => state.treatment.treatment);
  const treatmentForms = useSelector(
    (state) => state.treatmentForm.treatmentForms
  );

  const [selectedPatient, setSelectedPatient] = useState(null);
  const [selectedHospital, setSelectedHospital] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedDiagnoses, setSelectedDiagnoses] = useState([]);
  const [selectedMedicines, setSelectedMedicines] = useState([]);
  const [formContents, setFormContents] = useState({});
  const [imageUrls, setImageUrls] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchTreatmentById(id));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(fetchTreatmentForms());
  }, [dispatch]);

  useEffect(() => {
    if (treatment) {
      setSelectedPatient({
        value: treatment.patientId,
        label: treatment.patientName,
      });
      setSelectedHospital({
        value: treatment.hospitalId,
        label: treatment.hospitalName,
      });
      setSelectedCategory({
        value: treatment.categoryId,
        label: treatment.categoryName,
      });
      setSelectedService(treatment.serviceId);
      setSelectedDiagnoses(
        treatment.diagnoses.map((diagnose) => ({
          value: diagnose.id,
          label: diagnose.name,
        }))
      );
      setSelectedMedicines(
        treatment.medicines.map((medicine) => ({
          value: medicine.id,
          label: medicine.name,
        }))
      );
      setFormContents(
        treatment.treatmentForms.reduce(
          (acc, form) => ({ ...acc, [form.name]: form.content }),
          {}
        )
      );
      setImageUrls(treatment.documentUrls || []);
    }
  }, [treatment]);

  const handleFormContentChange = (name, content) => {
    setFormContents({
      ...formContents,
      [name]: content,
    });
  };

  const handleSave = () => {
    if (!selectedPatient) {
      toast.error(addTreatmentDictionary.errors.patientRequired);
      return false;
    }
    if (!selectedHospital) {
      toast.error(addTreatmentDictionary.errors.hospitalRequired);
      return false;
    }
    if (!selectedService) {
      toast.error(addTreatmentDictionary.errors.serviceRequired);
      return false;
    }
    if (selectedDiagnoses.length === 0) {
      toast.error(addTreatmentDictionary.errors.diagnosisRequired);
      return false;
    }
    if (selectedMedicines.length === 0) {
      toast.error(addTreatmentDictionary.errors.medicineRequired);
      return false;
    }
    if (!selectedCategory) {
      toast.error(addTreatmentDictionary.errors.categoryRequired);
      return false;
    }

    const treatmentFormsData = Object.keys(formContents).map((name) => ({
      treatmentFormId: treatmentForms.find((form) => form.name === name).id,
      content: formContents[name],
    }));

    const treatmentData = {
      patientId: selectedPatient?.value || 0,
      hospitalId: selectedHospital?.value || 0,
      serviceId: selectedService || 0,
      categoryId: selectedCategory?.value || 0,
      diagnoseIds: selectedDiagnoses.map((d) => d.value),
      medicineIds: selectedMedicines.map((m) => m.value),
      treatmentForms: treatmentFormsData,
      documentUrls: imageUrls,
    };
    dispatch(updateTreatment(id, treatmentData));
    navigate("/user/treatments");
  };

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const handleDeleteFile = (url) => {
    setImageUrls((prevUrls) => prevUrls.filter((item) => item !== url));
  };

  return (
    <div className="edit-treatment-container user-box-shadow">
      <h2>{addTreatmentDictionary.header}</h2>

      <div className="edit-treatment-form">
        <div className="edit-treatment-top">
          <div>
            <label>{addTreatmentDictionary.patientLabel}</label>
            <PatientSelect
              selectedPatient={selectedPatient}
              setSelectedPatient={setSelectedPatient}
            />
          </div>
          <div>
            <label>{addTreatmentDictionary.hospitalLabel}</label>
            <HospitalSelect
              selectedHospital={selectedHospital}
              setSelectedHospital={setSelectedHospital}
            />
          </div>
          <div>
            <label>{addTreatmentDictionary.serviceLabel}</label>
            <ServiceProcedureSelect
              selectedService={selectedService}
              setSelectedService={setSelectedService}
            />
          </div>
          <div>
            <label>{addTreatmentDictionary.diagnosisLabel}</label>
            <DiagnoseSelect
              selectedDiagnoses={selectedDiagnoses}
              setSelectedDiagnoses={setSelectedDiagnoses}
            />
          </div>
          <div>
            <label>{addTreatmentDictionary.medicinesLabel}</label>
            <MedicineSelect
              selectedMedicines={selectedMedicines}
              setSelectedMedicines={setSelectedMedicines}
            />
          </div>
          <div>
            <label>
              {Translation[language].treatmentOptions.categoriesTitle}
            </label>
            <CategorySelect
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
            />
          </div>
        </div>
        <div className="edit-treatment-examination-questionnaire">
          <h3>{addTreatmentDictionary.examinationQuestionnaire}</h3>
          {treatmentForms.map((form) => (
            <div key={form.id}>
              <label>{form.name}</label>
              <textarea
                placeholder={form.name}
                value={formContents[form.name] || ""}
                onChange={(e) =>
                  handleFormContentChange(form.name, e.target.value)
                }
              />
            </div>
          ))}
        </div>
        <div className="edit-treatment-document-container">
          <label>{addTreatmentDictionary.documentsLabel}</label>
          <UploadFile
            setImageUrls={setImageUrls}
            content={addTreatmentDictionary.uploadDocument}
            className="edit-treatment-document-container-upload-button"
            multiple={true}
          />
          {imageUrls.length > 0 && (
            <div className="uploaded-file-links">
              {imageUrls.map((url, index) => (
                <div key={index} className="uploaded-file-link">
                  <a href={url} target="_blank" rel="noopener noreferrer">
                    View Document {index + 1}
                  </a>
                  <IconButton
                    aria-label="delete"
                    onClick={() => handleDeleteFile(url)}
                    color="secondary"
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <Box
        display="flex"
        justifyContent="flex-end"
        mt={2}
        style={{ marginBottom: 10 }}
      >
        <Button
          variant="outlined"
          startIcon={<SaveIcon />}
          style={{
            color: "var(--app-main-color)",
            borderColor: "var(--app-main-color)",
            marginRight: "10px",
          }}
          onClick={handleSave}
        >
          {addTreatmentDictionary.saveButton}
        </Button>
        <Button
          variant="outlined"
          style={{
            color: "var(--app-main-color)",
            borderColor: "var(--app-main-color)",
            marginRight: "10px",
          }}
          onClick={handleOpen}
        >
          {addTreatmentDictionary.nextCheckupButton}
        </Button>
      </Box>
      <NewAppointment handleClose={handleClose} open={open} selectedPatient={selectedPatient}/>
    </div>
  );
};

export default EditTreatment;
