import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  expenses: [],
  loading: false,
  error: null,
};

const expenseSlice = createSlice({
  name: "expense",
  initialState,
  reducers: {
    fetchExpensesRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchExpensesSuccess: (state, action) => {
      state.loading = false;
      state.expenses = action.payload;
    },
    fetchExpensesFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    // Create Expense Reducers
    createExpenseRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    createExpenseSuccess: (state, action) => {
      state.loading = false;
      state.expenses.push(action.payload);
    },
    createExpenseFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    // Update Expense Reducers
    updateExpenseRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    updateExpenseSuccess: (state, action) => {
      state.loading = false;
      const index = state.expenses.findIndex(
        (expense) => expense.id === action.payload.id
      );
      if (index !== -1) {
        state.expenses[index] = action.payload;
      }
    },
    updateExpenseFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    // Delete Expense Reducers
    deleteExpenseRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    deleteExpenseSuccess: (state, action) => {
      state.loading = false;
      state.expenses = state.expenses.filter(
        (expense) => expense.id !== action.payload
      );
    },
    deleteExpenseFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchExpensesRequest,
  fetchExpensesSuccess,
  fetchExpensesFailure,
  createExpenseRequest,
  createExpenseSuccess,
  createExpenseFailure,
  updateExpenseRequest,
  updateExpenseSuccess,
  updateExpenseFailure,
  deleteExpenseRequest,
  deleteExpenseSuccess,
  deleteExpenseFailure,
} = expenseSlice.actions;

export default expenseSlice.reducer;
