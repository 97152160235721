import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  homeProfile: null,
  profile: null,
  loading: false,
  error: null,
  changePasswordSuccess: false,
  changeStatisticsPasswordSuccess: false,
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    fetchProfileRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchProfileSuccess: (state, action) => {
      state.profile = action.payload;
      state.loading = false;
    },
    fetchProfileFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    fetchHomeProfileSuccess: (state, action) => {
      state.homeProfile = action.payload;
      state.loading = false;
    },
    fetchHomeProfileFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    updateProfileRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    updateProfileSuccess: (state, action) => {
      state.profile = action.payload;
      state.loading = false;
    },
    updateProfileFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    changePasswordRequest: (state) => {
      state.loading = true;
      state.error = null;
      state.changePasswordSuccess = false;
    },
    changePasswordSuccess: (state) => {
      state.loading = false;
      state.changePasswordSuccess = true;
    },
    changePasswordFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    changeStatisticsPasswordRequest: (state) => {
      state.loading = true;
      state.error = null;
      state.changeStatisticsPasswordSuccess = false;
    },
    changeStatisticsPasswordSuccess: (state) => {
      state.loading = false;
      state.changeStatisticsPasswordSuccess = true;
    },
    changeStatisticsPasswordFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const {
  fetchProfileRequest,
  fetchProfileSuccess,
  fetchProfileFailure,
  fetchHomeProfileSuccess,
  fetchHomeProfileFailure,
  updateProfileRequest,
  updateProfileSuccess,
  updateProfileFailure,
  changePasswordRequest,
  changePasswordSuccess,
  changePasswordFailure,
  changeStatisticsPasswordRequest,
  changeStatisticsPasswordSuccess,
  changeStatisticsPasswordFailure,
} = profileSlice.actions;

export default profileSlice.reducer;
