import React from "react";
import { Modal, ModalDialog } from "@mui/joy";
import CloseIcon from "@mui/icons-material/Close";
import { Button, IconButton, TextField } from "@mui/material";
import "../../assets/styles/User/Appointment/SetAppointmentPriceModal.css"; // Add your styles here

const SetAppointmentPriceModal = ({
  open,
  handleClose,
  handleConfirm,
  setPrice,
}) => {
  const handlePriceChange = (event) => {
    setPrice(event.target.value);
  };

  const handleOkClick = () => {
    handleConfirm();
  };

  return (
    <Modal
      open={open}
      onClose={(_, reason) => reason === "escapeKeyDown" && handleClose()}
      aria-labelledby="set-appointment-price-title"
      className="set-appointment-price-modal-container"
    >
      <ModalDialog variant="plain">
        <div className="set-appointment-price-modal">
          <div className="set-appointment-price-modal-header">
            <h2 id="set-appointment-price-title">
              Randevuya qiymət təyin edin
            </h2>
            <IconButton onClick={handleClose}>
              <CloseIcon color="error" />
            </IconButton>
          </div>
          <form
            className="set-appointment-price-form"
            onSubmit={(e) => e.preventDefault()}
          >
            <TextField fullWidth label="Qiymət" onChange={handlePriceChange} />
            <Button
              variant="contained"
              style={{ backgroundColor: "var(--app-main-color)" }}
              onClick={handleOkClick}
            >
              OK
            </Button>
          </form>
        </div>
      </ModalDialog>
    </Modal>
  );
};

export default SetAppointmentPriceModal;
