import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchHospitalStatistics } from "../../store/fetchs/statisticsActions"; // Import the action
import "../../assets/styles/User/Statistic/StatisticsHospital.css";

const StatisticsHospital = ({ dateRange }) => {
  const dispatch = useDispatch();
  const hospitalStatistics = useSelector(
    (state) => state.statistics.hospitalStatistics
  ); // Retrieve the statistics from the store

  useEffect(() => {
    if (dateRange) {
      dispatch(fetchHospitalStatistics(dateRange));
    }
  }, [dispatch, dateRange]);

  return (
    <>
      <h4 className="statistic-hospitals-header">Hospitals</h4>
      <div className="statistic-hospitals-body">
        {hospitalStatistics.length > 0 ? (
          hospitalStatistics.map((hospital, index) => (
            <div key={index} className="hospital-statistic">
              <span className="hospital-name">{hospital.hospitalName}</span>
              <span className="hospital-price">{hospital.totalPrice} AZN</span>
            </div>
          ))
        ) : (
          <p>No data available for the selected date range.</p>
        )}
      </div>
    </>
  );
};

export default StatisticsHospital;
