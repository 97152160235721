import { useNavigate } from "react-router-dom";
import { LanguageContext } from "../../LanguageContext";
import { useContext, useState } from "react";
import Translation from "../../language.json";
import NewAppointment from "../AddAppointment/NewAppointment";
import add from "../../assets/images/addicon.png";
import "../../assets/styles/User/Home/NewCard.css";

let NewCards = () => {
  const { language } = useContext(LanguageContext);
  const newCardsDictionary = Translation[language].newCards;
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <div
        className="home-new-card"
        onClick={() => {
          navigate("/user/add-patient");
        }}
      >
        <p>{newCardsDictionary.newPatient}</p>
        <img src={add} alt="add" />
      </div>
      <div
        className="home-new-card"
        onClick={() => {
          navigate("/user/add-treatment");
        }}
      >
        <p>{newCardsDictionary.newTreatment}</p>
        <img src={add} alt="add" />
      </div>
      <div className="home-new-card" onClick={handleOpen}>
        <p>{newCardsDictionary.newAppointment}</p>
        <img src={add} alt="add" />
      </div>
      <NewAppointment open={open} handleClose={handleClose} />
    </>
  );
};

export default NewCards;
