import React, { useContext } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { LanguageContext } from "../../LanguageContext";
import Translation from "../../language.json";
import "../../assets/styles/User/EditPatient/PatientAppointments.css";

const statusColors = {
  Finished: "green",
  Pending: "orange",
  Cancelled: "red",
};

const PatientAppointments = ({ appointments = [] }) => {
  const { language } = useContext(LanguageContext);


  return (
    <div className="user-box-shadow edit-patient-appointments">
      <div className="user-appointment-header">
        <h2>{Translation[language].navbar.appointments}</h2>
      </div>
      <TableContainer>
        <Table aria-label="appointments table">
          <TableBody>
            {appointments.map((appointment, index) => (
              <TableRow key={appointment.id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{appointment.date.split("T")[0]}</TableCell>
                <TableCell>
                  {appointment.date.split("T")[1].slice(0, 5)}
                </TableCell>
                <TableCell style={{ color: statusColors[appointment.status] }}>
                  {appointment.status === "Pending"
                    ? Translation[language].todayAppointments.statuses.pending
                    : appointment.status === "Finished"
                    ? Translation[language].todayAppointments.statuses.finished
                    : Translation[language].todayAppointments.statuses
                        .cancelled}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default PatientAppointments;
