import React, { useContext, useEffect } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { LanguageContext } from "../../LanguageContext";
import Translation from "../../language.json";
import { fetchHospitals } from "../../store/fetchs/hospitalActions";

const HospitalSelect = ({ selectedHospital, setSelectedHospital }) => {
  const { language } = useContext(LanguageContext);

  const dispatch = useDispatch();
  const hospitals = useSelector((state) => state.hospital.hospitals);

  useEffect(() => {
    dispatch(fetchHospitals());
  }, [dispatch]);

  const handleSelectChange = (selectedOption) => {
    setSelectedHospital(selectedOption);
  };

  const hospitalOptions = hospitals.map((hospital) => ({
    value: hospital.id,
    label: hospital.name,
  }));

  return (
    <Select
      value={hospitalOptions.find(
        (option) => option.value === selectedHospital?.value
      )}
      onChange={handleSelectChange}
      options={hospitalOptions}
      placeholder={Translation[language].hospitalSelect}
      isClearable
    />
  );
};

export default HospitalSelect;
