import React, { useState, useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import PhoneNumber from "../PhoneNumber";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { forgetPasswordFetch } from "./../../store/fetchs/authActions";
import Pace from "pace-js";
import { toast } from "react-toastify";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { forgetPasswordFailure } from "../../store/reducers/authReducer";
import Translation from "../../language.json";
import { LanguageContext } from "../../LanguageContext";
import { useLoading } from "../../LoadingContext";
import "react-toastify/dist/ReactToastify.css";
import "../../assets/styles/Auth/Register.css";

const ForgetPassword = () => {
  const { setLoading } = useLoading();
  const { language } = useContext(LanguageContext);
  let forgetPasswordDictionary = Translation[language].forgetPassword;
  const [phone, setPhone] = useState("");
  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (phone) {
      const phoneNumber = parsePhoneNumberFromString(phone, "AZ"); // Change 'AZ' to the default country code you want to use
      if (phoneNumber) {
        setIsPhoneValid(phoneNumber.isValid());
      } else {
        setIsPhoneValid(false);
      }
    } else {
      setIsPhoneValid(false);
    }
  }, [phone]);

  const handleForgetPassword = () => {
    const sanitizedPhone = phone.replace(/\D/g, ""); // Remove non-digit characters
    Pace.restart(); // Start Pace.js loading animation
    setLoading(true);
    dispatch(forgetPasswordFetch(sanitizedPhone))
      .then(() => {
        Pace.stop(); // Stop Pace.js loading animation
        setLoading(false);
        toast.success("Yeni şifrə nömrənizə göndərildi.");
        navigate("/auth/login");
      })
      .catch((error) => {
        setLoading(false);
        Pace.stop(); // Stop Pace.js loading animation
        toast.error(error.message); // Show error notification
        setPhone("");
        dispatch(forgetPasswordFailure(error.message));
      });
  };

  return (
    <div className="auth-register-container">
      <h2>{forgetPasswordDictionary.resetPassword}</h2>
      <div className="auth-register-input">
        <PhoneNumber phone={phone} setPhone={setPhone} />
      </div>
      <div className="auth-register-input" style={{ height: 45 }}>
        <Button
          variant="contained"
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "var(--app-main-color)",
          }}
          onClick={handleForgetPassword}
          disabled={!isPhoneValid} // Disable button if phone is not valid
        >
          {forgetPasswordDictionary.next}
        </Button>
      </div>
      <Button
        onClick={() => {
          navigate("/auth/login");
        }}
      >
        {forgetPasswordDictionary.back}
      </Button>
    </div>
  );
};

export default ForgetPassword;
