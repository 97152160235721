import { useContext } from "react";
import Translation from "../../language.json";
import { LanguageContext } from "../../LanguageContext";
import "../../assets/styles/User/Statistic/StatisticGeneralStatistics.css";

let HospitalStatisticGeneralStatistics = ({ generalStatistics }) => {
  const { language } = useContext(LanguageContext);
  const statisticGeneralStatisticsDictionary =
    Translation[language].statisticGeneralStatistics;

  return (
    <>
      <h4 className="statistic-general-statistics-header">
        {statisticGeneralStatisticsDictionary.header}
      </h4>
      <div className="statistic-general-statistics-body">
        <div>
          <div>{statisticGeneralStatisticsDictionary.appointments}</div>
          <div style={{ color: "#426CFF ", fontSize: 40 }}>
            {generalStatistics?.totalAppointments}
          </div>
        </div>
        <div className="statistic-general-statistics-middle-container">
          <div>{statisticGeneralStatisticsDictionary.treatments}</div>
          <div style={{ color: "#4DE7E7", fontSize: 40 }}>
            {generalStatistics?.totalTreatments}
          </div>
        </div>
        <div className="statistic-general-statistics-middle-container">
          <div>{statisticGeneralStatisticsDictionary.patients}</div>
          <div style={{ color: "#44E89B", fontSize: 40 }}>
            {generalStatistics?.totalPatients}
          </div>
        </div>
        <div>
          <div>{statisticGeneralStatisticsDictionary.income}</div>
          <div style={{ color: "#44E89B", fontSize: 40 }}>
            {generalStatistics?.totalPrice}
          </div>
        </div>
      </div>
    </>
  );
};

export default HospitalStatisticGeneralStatistics;
