import React, { useState } from "react";
import { TextField, IconButton, Box } from "@mui/material";
import { Delete as DeleteIcon, Edit as EditIcon, Save as SaveIcon } from "@mui/icons-material";

const ExpenseItem = ({ item, onSave, onDelete, isEditing, onEdit }) => {
  const [name, setName] = useState(item.name);
  const [price, setPrice] = useState(item.price);

  const handleSave = () => {
    onSave(item.id, name, price);
  };

  return (
    <Box display="flex" alignItems="center" mb={2}>
      <TextField
        variant="outlined"
        value={name}
        onChange={(e) => setName(e.target.value)}
        size="small"
        disabled={!isEditing}
        style={{ marginRight: 8 }}
      />
      <TextField
        variant="outlined"
        type="number"
        value={price}
        onChange={(e) => setPrice(e.target.value)}
        size="small"
        disabled={!isEditing}
        style={{ marginRight: 8 }}
      />
      <TextField
        variant="outlined"
        value={new Date(item.date).toLocaleDateString()}
        size="small"
        disabled
        style={{ marginRight: 8 }}
      />
      <IconButton onClick={() => onDelete(item.id)} color="error">
        <DeleteIcon />
      </IconButton>
      {isEditing ? (
        <IconButton onClick={handleSave} color="primary">
          <SaveIcon />
        </IconButton>
      ) : (
        <IconButton onClick={() => onEdit(item.id)} color="primary">
          <EditIcon />
        </IconButton>
      )}
    </Box>
  );
};

export default ExpenseItem;
