import React, { useState, useEffect } from "react";
import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  getAppointmentDuration,
  fetchAppointmentsByDate,
  makeReservation,
} from "../store/fetchs/appointmentActions";
import { toast } from "react-toastify";
import { useLoading } from "../LoadingContext";
import PhoneNumber from "../components/PhoneNumber";
import whatsapp from "../assets/images/whatsapp.png";
import facebook from "../assets/images/facebook.png";
import instagram from "../assets/images/instagram.png";
import web from "../assets/images/web.png";
import doctor from "../assets/images/doctor.png";
import "../assets/styles/Reservation.css";

const Reservation = () => {
  const dispatch = useDispatch();
  const { setLoading } = useLoading();
  const appointmentsByDate = useSelector(
    (state) => state.appointment.appointmentsByDate
  );
  const [fullName, setFullName] = useState("");
  const [phone, setPhone] = useState("");
  const [date, setDate] = useState(dayjs());
  const [time, setTime] = useState("");
  const [timeSlots, setTimeSlots] = useState([]);
  const [reservedSlots, setReservedSlots] = useState([]);

  useEffect(() => {
    const fetchDuration = async () => {
      const duration = await dispatch(getAppointmentDuration());
      generateTimeSlots(duration.replace(/"/g, ""));
    };
    fetchDuration();
  }, [dispatch]);

  useEffect(() => {
    if (date) {
      const formattedDate = dayjs(date).format("YYYY-MM-DD");
      dispatch(fetchAppointmentsByDate(formattedDate));
    }
  }, [date, dispatch]);

  useEffect(() => {
    if (appointmentsByDate) {
      const reserved = appointmentsByDate.map(
        (appointment) => appointment.time
      );
      setReservedSlots(reserved);
    }
  }, [appointmentsByDate]);

  const generateTimeSlots = (duration) => {
    let [hours, minutes] = duration.split(":").map(Number);
    const totalMinutes = hours * 60 + minutes;
    const slots = [];
    let currentTime = dayjs().hour(9).minute(0);
    const endTime = dayjs().hour(19).minute(0);

    while (currentTime.isBefore(endTime)) {
      slots.push(currentTime.format("HH:mm"));
      currentTime = currentTime.add(totalMinutes, "minute");
    }

    setTimeSlots(slots);
  };

  const handleSetAppointment = () => {
    if (fullName === "") {
      toast.error("Adınızı qeyd edin!");
      return;
    }
    if (phone === "") {
      toast.error("Telefon nömrəsini qeyd edin!");
      return;
    }
    if (!date) {
      toast.error("Tarixi qeyd etməmisiz!");
      return;
    }
    if (!time) {
      toast.error("Zamanı qeyd etməmisiz!");
      return;
    }

    const dateString = dayjs(date).format("YYYY-MM-DD");
    const timeString = `${time}:00`;
    const reservationData = {
      fullName,
      phoneNumber: phone,
      reservationDate: dateString,
      reservationTime: timeString,
    };

    setLoading(true);
    dispatch(makeReservation(reservationData))
      .then(() => {
        setFullName("");
        setPhone("");
        setTime("");
        toast.success("Randevu qeydə alındı");
        setLoading(false);
      })
      .catch((err) => {
        setFullName("");
        setPhone("");
        setTime("");
        toast.error(err.message || "Xəta baş verdi birdə yoxlayın");
        setLoading(false);
      });
  };

  return (
    <div className="reservation-container">
      <div class="reservation-info-container">
        <img src={doctor} alt="doctor" class="reservation-doctor" />
        <div style={{ marginLeft: "9rem" }}>
          <h2>Aynur Bədəvi</h2>
          <p>Dermatoloq</p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 15,
              marginTop: 8,
            }}
          >
            <a href="https://www.instagram.com/dermatoloq_badavi.aynur?igsh=NzQ5ZmRjaWtwdDY3">
              <img src={instagram} alt="instagram"></img>
            </a>
            <a href="https://vt.tiktok.com/ZSYKdjH7n/">
              <img src={web} alt="web"></img>
            </a>
            <a href="https://wa.me/+994702821121">
              <img src={whatsapp} alt="whatsapp"></img>
            </a>
            <a href="https://www.facebook.com/share/x3yqg6dYRYMFwWNW/?mibextid=LQQJ4d">
              <img src={facebook} alt="facebook"></img>
            </a>
          </div>
        </div>
      </div>
      <div className="reservation-form-container user-box-shadow">
        <h2 id="reservation-form-title" className="reservation-form-title">
          Qəbula yazıl
        </h2>
        <form className="reservation-form">
          <FormControl fullWidth margin="normal">
            <label className="reservation-form-label">
              Qəbula yazılmaq üçün aşağıdakı xanaları doldurun
            </label>
            <TextField
              placeholder={"Ad, Soyad"}
              variant="outlined"
              fullWidth
              size="small"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
            />
          </FormControl>
          <PhoneNumber phone={phone} setPhone={setPhone} />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoItem>
              <DatePicker
                className="reservation-date-select"
                fullWidth
                value={date}
                onChange={(newValue) => setDate(dayjs(newValue))}
                minDate={dayjs()}
              />
            </DemoItem>
          </LocalizationProvider>
          <FormControl
            variant="outlined"
            fullWidth
            margin="normal"
            size="small"
            disabled={!date}
          >
            <InputLabel>Vaxt</InputLabel>
            <Select
              label="Vaxt"
              value={time}
              onChange={(e) => setTime(e.target.value)}
            >
              <MenuItem value="">
                <em>Seçilməyib</em>
              </MenuItem>
              {timeSlots.map((slot, index) => (
                <MenuItem
                  key={index}
                  value={slot}
                  disabled={reservedSlots.includes(slot)}
                >
                  {slot}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            variant="contained"
            color="primary"
            className="app-theme-color"
            onClick={handleSetAppointment}
          >
            Qəbula yazıl
          </Button>
        </form>
      </div>
    </div>
  );
};

export default Reservation;
