import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  phoneNumber: null,
  checkPhoneMessage: null,
  fullName: null,
  userExist: false,
  accessToken: null,
  error: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authSuccess: (state, action) => {
      localStorage.setItem("accessToken", action.payload.accessToken);
      localStorage.setItem("refreshToken", action.payload.refreshToken);
      localStorage.setItem("phoneNumber", action.payload.phoneNumber);
      localStorage.setItem("fullName", action.payload.fullName);
      localStorage.setItem("profileImgUrl", action.payload.profileImgUrl);

      return {
        ...state,
        accessToken: action.payload.accessToken,
        error: null,
      };
    },
    authFailure: (state, action) => {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("phoneNumber");
      localStorage.removeItem("fullName");
      localStorage.removeItem("profileImgUrl");
      return {
        ...state,
        accessToken: null,
        error: action.payload,
      };
    },
    confirmOTPSuccess: (state, action) => {
      return {
        ...state,
        emailConfirmed: true,
        error: null,
      };
    },
    confirmOTPFailure: (state, action) => {
      return {
        ...state,
        emailConfirmed: false,
        error: action.payload,
      };
    },
    checkPhoneSuccess: (state, action) => {
      return {
        ...state,
        checkPhoneMessage: action.payload.message,
        phoneNumber: action.payload.phoneNumber,
        userExist: false,
        error: null,
      };
    },
    checkPhoneFailure: (state, action) => {
      return {
        ...state,
        checkPhoneMessage: null,
        phoneNumber: null,
        userExist: true,
        error: action.payload,
      };
    },
    forgetPasswordSuccess: (state, action) => {
      return {
        ...state,
        passwordSended: true,
        error: null,
      };
    },
    forgetPasswordFailure: (state, action) => {
      return {
        ...state,
        passwordSended: false,
        error: action.payload,
      };
    },
  },
});

export const {
  authSuccess,
  authFailure,
  confirmOTPSuccess,
  confirmOTPFailure,
  checkPhoneSuccess,
  checkPhoneFailure,
  forgetPasswordSuccess,
  forgetPasswordFailure,
} = authSlice.actions;
export default authSlice.reducer;
