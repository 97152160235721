import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LanguageContext } from "../../LanguageContext";
import TreatmentOptionsSegment from "./TreatmentOptionsSegment";
import Translation from "../../language.json";

// Import category actions
import {
  fetchCategories,
  createCategory,
  updateCategory,
  deleteCategory,
} from "../../store/fetchs/categoryActions"; // Adjust the import path as necessary

import {
  fetchServices,
  createService,
  updateService,
  deleteService,
} from "../../store/fetchs/serviceActions"; // Adjust the import path as necessary

import {
  fetchDiagnoses,
  createDiagnose,
  updateDiagnose,
  deleteDiagnose,
} from "../../store/fetchs/diagnoseActions"; // Adjust the import path as necessary

import {
  fetchMedicines,
  createMedicine,
  updateMedicine,
  deleteMedicine,
} from "../../store/fetchs/medicineActions"; // Adjust the import path as necessary

import {
  fetchTreatmentForms,
  createTreatmentForm,
  updateTreatmentForm,
  deleteTreatmentForm,
} from "../../store/fetchs/treatmentFormActions"; // Adjust the import path as necessary

import {
  fetchHospitals,
  createHospital,
  updateHospital,
  deleteHospital,
} from "../../store/fetchs/hospitalActions"; // Adjust the import path as necessary

const TreatmentOptions = () => {
  const { language } = useContext(LanguageContext);
  const treatmentOptionsDictionary = Translation[language].treatmentOptions;

  const dispatch = useDispatch();

  const services = useSelector((state) => state.service.services || []);
  const diagnoses = useSelector((state) => state.diagnose.diagnoses || []);
  const medicines = useSelector((state) => state.medicine.medicines || []);
  const hospitals = useSelector((state) => state.hospital.hospitals || []);
  const treatmentForms = useSelector(
    (state) => state.treatmentForm.treatmentForms || []
  );
  const categories = useSelector((state) => state.category.categories || []);

  const treatmentFormMethods = {
    fetchItems: () => dispatch(fetchTreatmentForms()),
    addItem: () => dispatch(createTreatmentForm("")),
    saveItem: (id, name) => dispatch(updateTreatmentForm(id, name)),
    deleteItem: (id) => dispatch(deleteTreatmentForm(id)),
  };

  const serviceMethods = {
    fetchItems: () => dispatch(fetchServices()),
    addItem: () => dispatch(createService("")),
    saveItem: (id, name) => dispatch(updateService(id, name)),
    deleteItem: (id) => dispatch(deleteService(id)),
  };

  const diagnoseMethods = {
    fetchItems: () => dispatch(fetchDiagnoses()),
    addItem: () => dispatch(createDiagnose("")),
    saveItem: (id, name) => dispatch(updateDiagnose(id, name)),
    deleteItem: (id) => dispatch(deleteDiagnose(id)),
  };

  const hospitalMethods = {
    fetchItems: () => dispatch(fetchHospitals()),
    addItem: () => dispatch(createHospital("")),
    saveItem: (id, name) => dispatch(updateHospital(id, name)),
    deleteItem: (id) => dispatch(deleteHospital(id)),
  };

  const medicineMethods = {
    fetchItems: () => dispatch(fetchMedicines()),
    addItem: () => dispatch(createMedicine("")),
    saveItem: (id, name) => dispatch(updateMedicine(id, name)),
    deleteItem: (id) => dispatch(deleteMedicine(id)),
  };

  const categoryMethods = {
    fetchItems: () => dispatch(fetchCategories()),
    addItem: () => dispatch(createCategory("")),
    saveItem: (id, name) => dispatch(updateCategory(id, name)),
    deleteItem: (id) => dispatch(deleteCategory(id)),
  };

  return (
    <>
      <div className="border-right border-bottom">
        <TreatmentOptionsSegment
          title={treatmentOptionsDictionary.treatmentFormTitle}
          items={treatmentForms}
          {...treatmentFormMethods}
        />
      </div>
      <div className="border-bottom">
        <TreatmentOptionsSegment
          title={treatmentOptionsDictionary.customServicesTitle}
          items={services}
          {...serviceMethods}
        />
      </div>
      <div className="border-right border-bottom">
        <TreatmentOptionsSegment
          title={treatmentOptionsDictionary.customDiagnosesTitle}
          items={diagnoses}
          {...diagnoseMethods}
        />
      </div>
      <div className="border-bottom">
        <TreatmentOptionsSegment
          title={treatmentOptionsDictionary.medicinesTitle}
          items={medicines}
          {...medicineMethods}
        />
      </div>
      <div className="border-right">
        <TreatmentOptionsSegment
          title={treatmentOptionsDictionary.hospitalsTitle}
          items={hospitals}
          {...hospitalMethods}
        />
      </div>
      <div>
        <TreatmentOptionsSegment
          title={treatmentOptionsDictionary.categoriesTitle}
          items={categories}
          {...categoryMethods}
        />
      </div>
    </>
  );
};

export default TreatmentOptions;
