import {
  authSuccess,
  checkPhoneSuccess,
  forgetPasswordSuccess,
  confirmOTPSuccess,
} from "../reducers/authReducer";
import fetchBaseUrl from "./fetchBaseURL.json";

const baseUrl = fetchBaseUrl.API_BASE_URL;

export const loginFetch = (phoneNumber, password) => (dispatch) => {
  return fetch(`${baseUrl}/api/auth/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ phoneNumber, password }),
  })
    .then(async (response) => {
      if (!response.ok) {
        throw new Error(await response.text());
      }
      return response.json();
    })
    .then((data) => {
      dispatch(authSuccess(data));
    });
};

export const registerFetch =
  (phoneNumber, name, surname, password) => (dispatch) => {
    return fetch(`${baseUrl}/api/auth/register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ phoneNumber, name, surname, password }),
    })
      .then(async (response) => {
        if (!response.ok) {
          throw new Error(await response.text());
        }
        return response.json();
      })
      .then((data) => {
        dispatch(authSuccess(data));
      });
  };

export const checkPhoneNumberFetch = (phoneNumber) => (dispatch) => {
  return fetch(`${baseUrl}/api/auth/check-phone-number`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ phoneNumber }),
  })
    .then(async (response) => {
      if (!response.ok) {
        throw new Error(await response.text());
      }
      return response.json();
    })
    .then((data) => {
      dispatch(checkPhoneSuccess(data));
    });
};

export const resendOtpFetch = (phoneNumber) => (dispatch) => {
  return fetch(`${baseUrl}/api/auth/resend-otp`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ phoneNumber }),
  })
    .then(async (response) => {
      if (!response.ok) {
        throw new Error(await response.text());
      }
      return response.json();
    })
    .then((data) => {
      dispatch(checkPhoneSuccess(data));
    });
};

export const forgetPasswordFetch = (phoneNumber) => (dispatch) => {
  return fetch(`${baseUrl}/api/auth/forget-password`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ phoneNumber }),
  })
    .then(async (response) => {
      if (!response.ok) {
        throw new Error(await response.text());
      }
      return response.text();
    })
    .then((data) => {
      dispatch(forgetPasswordSuccess(data));
    });
};

export const confirmOTPFetch =
  (phoneNumber, verificationCode) => (dispatch) => {
    return fetch(`${baseUrl}/api/auth/verify-otp`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ phoneNumber, verificationCode }),
    })
      .then(async (response) => {
        if (!response.ok) {
          throw new Error(await response.text());
        }
        return response.text();
      })
      .then((data) => {
        dispatch(confirmOTPSuccess(data));
      });
  };

export const refreshTokenFetch = () => (dispatch) => {
  const refreshToken = localStorage.getItem("refreshToken");
  return fetch(`${baseUrl}/api/auth/refresh`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ refreshToken }),
  })
    .then(async (response) => {
      if (!response.ok) {
        throw new Error(await response.text());
      }
      return response.json();
    })
    .then((data) => {
      dispatch(authSuccess(data));
      return data;
    })
    .catch((error) => {
      logoutUser();
    });
};

export const logoutFetch = () => (dispatch) => {
  let accessToken = localStorage.getItem("accessToken");

  return fetch(`${baseUrl}/api/auth/logout`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then(async (response) => {
      if (!response.ok) {
        throw new Error(await response.json());
      }
      dispatch(logoutUser());
    })
    .catch((error) => {
      console.log(error.message);
    });
};

export const logoutUser = () => (dispatch) => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("profileImgUrl");
  localStorage.removeItem("fullName");
  localStorage.removeItem("phoneNumber");
  window.location.reload();
};
