import { Button } from "@mui/base";
import appointment from "../../assets/images/appointments.png";
import homeProfile from "../../assets/images/home-profile.png";
import statistics from "../../assets/images/statistics.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { fetchHomeProfileData } from "../../store/fetchs/profileActions";
import "../../assets/styles/User/Home/HomeProfile.css";

let HomeProfile = () => {
  const data = useSelector((state) => state.profile.homeProfile);
  const [image, setImage] = useState("");
  const [name, setName] = useState("");
  const [spec, setSpec] = useState("");
  const [hospitals, setHospitals] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchHomeProfileData());
  }, [dispatch]);

  useEffect(() => {
    if (data) {
      setImage(data.profileImage);
      setName(data.fullName);
      setSpec(data.specialization);
      setHospitals(`${data.hospitals[1]} • ${data.hospitals[0]}`);
    }
  }, [data]);

  return (
    <>
      <img src={image} alt="profile" className="home-profile-img" />
      <div className="home-profile-info">
        <p>{name}</p>
        <p>{spec}</p>
        <p>{hospitals}</p>
      </div>
      <div className="home-profile-btn-container">
        <Button
          className="home-profile-btn"
          onClick={() => {
            navigate("/user/appointments");
          }}
        >
          <img src={appointment} alt="appoint" />
        </Button>
        <Button
          className="home-profile-btn"
          onClick={() => {
            navigate("/user/profile");
          }}
        >
          <img src={homeProfile} alt="profile" />
        </Button>
        <Button
          className="home-profile-btn"
          onClick={() => {
            navigate("/user/statistics");
          }}
        >
          <img src={statistics} alt="stats" />
        </Button>
      </div>
    </>
  );
};

export default HomeProfile;
