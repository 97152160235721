import {
  fetchGeneralStatisticsRequest,
  fetchGeneralStatisticsSuccess,
  fetchGeneralStatisticsFailure,
  fetchAppealStatisticsRequest,
  fetchAppealStatisticsSuccess,
  fetchAppealStatisticsFailure,
  fetchCheckPasswordRequest,
  fetchCheckPasswordSuccess,
  fetchCheckPasswordFailure,
  fetchHospitalStatisticsRequest,
  fetchHospitalStatisticsSuccess,
  fetchHospitalStatisticsFailure,
  fetchHospitalAppointmentsPriceRequest,
  fetchHospitalAppointmentsPriceSuccess,
  fetchHospitalAppointmentsPriceFailure,
  fetchHospitalAllStatisticsRequest,
  fetchHospitalAllStatisticsSuccess,
  fetchHospitalAllStatisticsFailure,
} from "../reducers/statisticsReducer";
import fetchWithAuth from "./fetchWithAuth";
import fetchBaseUrl from "./fetchBaseURL.json";

const baseUrl = fetchBaseUrl.API_BASE_URL;

const formatDate = (date) => {
  if (!date) return "";
  return date;
};

// Add fetchCheckPassword function
export const fetchCheckPassword = (password) => (dispatch) => {
  dispatch(fetchCheckPasswordRequest());
  return fetchWithAuth(`${baseUrl}/api/Statistics/check-password`, {
    method: "POST",
    body: JSON.stringify(password),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.message);
      }
      return response.text();
    })
    .then((data) => {
      dispatch(fetchCheckPasswordSuccess());
    })
    .catch((err) => {
      dispatch(
        fetchCheckPasswordFailure(err.message || "Error checking password")
      );
    });
};

// Fetch General Statistics
export const fetchGeneralStatistics =
  ({ startDate, endDate }) =>
  (dispatch) => {
    dispatch(fetchGeneralStatisticsRequest());
    return fetchWithAuth(
      `${baseUrl}/api/Statistics/get-general-statistics?startDate=${formatDate(
        startDate
      )}&endDate=${formatDate(endDate)}`,
      {
        method: "GET",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        dispatch(fetchGeneralStatisticsSuccess(data));
      })
      .catch((err) => {
        dispatch(fetchGeneralStatisticsFailure(err.message));
      });
  };

// Fetch Appeal Statistics
export const fetchAppealStatistics =
  ({ startDate, endDate }) =>
  (dispatch) => {
    dispatch(fetchAppealStatisticsRequest());
    return fetchWithAuth(
      `${baseUrl}/api/Statistics/get-patients-come-from?startDate=${formatDate(
        startDate
      )}&endDate=${formatDate(endDate)}`,
      {
        method: "GET",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        dispatch(fetchAppealStatisticsSuccess(data));
      })
      .catch((err) => {
        dispatch(fetchAppealStatisticsFailure(err.message));
      });
  };

export const fetchHospitalStatistics =
  ({ startDate, endDate }) =>
  (dispatch) => {
    dispatch(fetchHospitalStatisticsRequest());
    return fetchWithAuth(
      `${baseUrl}/api/Statistics/get-hospital-prices?startDate=${formatDate(
        startDate
      )}&endDate=${formatDate(endDate)}`,
      {
        method: "GET",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        dispatch(fetchHospitalStatisticsSuccess(data));
      })
      .catch((err) => {
        dispatch(fetchHospitalStatisticsFailure(err.message));
      });
  };

export const fetchHospitalAllStatistics = (id) => (dispatch) => {
  dispatch(fetchHospitalAllStatisticsRequest());
  return fetchWithAuth(
    `${baseUrl}/api/Statistics/get-hospital-statistics?id=${id}`,
    {
      method: "GET",
    }
  )
    .then((response) => response.json())
    .then((data) => {
      dispatch(fetchHospitalAllStatisticsSuccess(data));
    })
    .catch((err) => {
      dispatch(fetchHospitalAllStatisticsFailure(err.message));
    });
};

// Add fetchHospitalAppointmentsPrice function
export const fetchHospitalAppointmentsPrice =
  ({ startDate, endDate, page, pageSize }) =>
  (dispatch) => {
    dispatch(fetchHospitalAppointmentsPriceRequest());
    return fetchWithAuth(
      `${baseUrl}/api/Statistics/get-statistics-appointments-with-prices?startdate=${formatDate(
        startDate
      )}&enddate=${formatDate(endDate)}&page=${page}&pagesize=${pageSize}`,
      {
        method: "GET",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        dispatch(fetchHospitalAppointmentsPriceSuccess(data));
      })
      .catch((err) => {
        dispatch(fetchHospitalAppointmentsPriceFailure(err.message));
      });
  };
