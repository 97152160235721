import React, { useContext, useEffect } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { LanguageContext } from "../../LanguageContext";
import Translation from "../../language.json";
import { fetchCategories } from "../../store/fetchs/categoryActions"; // Adjust the import path as necessary

const CategorySelect = ({ selectedCategory, setSelectedCategory }) => {
  const { language } = useContext(LanguageContext);

  const dispatch = useDispatch();
  const categories = useSelector((state) => state.category.categories);

  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  const handleSelectChange = (selectedOption) => {
    setSelectedCategory(selectedOption);
  };

  const categoryOptions = categories.map((category) => ({
    value: category.id,
    label: category.name,
  }));

  return (
    <Select
      value={categoryOptions.find(
        (option) => option.value === selectedCategory?.value
      )}
      onChange={handleSelectChange}
      options={categoryOptions}
      placeholder={Translation[language].treatmentOptions.categoriesTitle}
      isClearable
    />
  );
};

export default CategorySelect;
