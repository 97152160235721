import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  generalStatistics: {
    totalAppointments: 0,
    totalTreatments: 0,
    totalPatients: 0,
  },
  appealStatistics: {
    socialMedia: 0,
    doctorReferral: 0,
    clinicReferral: 0,
    personal: 0,
  },
  hospitalStatistics: [],
  hospitalAllStatistics: [],
  hospitalAppointmentsPrice: {
    items: [],
    meta: {
      page: 0,
      pageSize: 0,
      totalPages: 0,
    },
  },
  isAuthenticated: false,
  loading: false,
  error: null,
};

const statisticsSlice = createSlice({
  name: "statistics",
  initialState,
  reducers: {
    // Check Password
    fetchCheckPasswordRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchCheckPasswordSuccess: (state, action) => {
      state.loading = false;
      state.isAuthenticated = true;
    },
    fetchCheckPasswordFailure: (state, action) => {
      state.loading = false;
      state.isAuthenticted = false;
      state.error = action.payload;
    },
    // General Statistics Reducers
    fetchGeneralStatisticsRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchGeneralStatisticsSuccess: (state, action) => {
      state.loading = false;
      state.generalStatistics = action.payload;
    },
    fetchGeneralStatisticsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    // Appeal Statistics Reducers
    fetchAppealStatisticsRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchAppealStatisticsSuccess: (state, action) => {
      state.loading = false;
      state.appealStatistics = action.payload;
    },
    fetchAppealStatisticsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    // Hospital Statistics Reducers
    fetchHospitalStatisticsRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchHospitalStatisticsSuccess: (state, action) => {
      state.loading = false;
      state.hospitalStatistics = action.payload;
    },
    fetchHospitalStatisticsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    // Hospital All Statistics Reducers
    fetchHospitalAllStatisticsRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchHospitalAllStatisticsSuccess: (state, action) => {
      state.loading = false;
      state.hospitalAllStatistics = action.payload;
    },
    fetchHospitalAllStatisticsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    // Hospital Appointments Price Reducers
    fetchHospitalAppointmentsPriceRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchHospitalAppointmentsPriceSuccess: (state, action) => {
      state.loading = false;
      state.hospitalAppointmentsPrice = action.payload;
    },
    fetchHospitalAppointmentsPriceFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchCheckPasswordRequest,
  fetchCheckPasswordSuccess,
  fetchCheckPasswordFailure,
  fetchGeneralStatisticsRequest,
  fetchGeneralStatisticsSuccess,
  fetchGeneralStatisticsFailure,
  fetchAppealStatisticsRequest,
  fetchAppealStatisticsSuccess,
  fetchAppealStatisticsFailure,
  fetchHospitalStatisticsRequest,
  fetchHospitalStatisticsSuccess,
  fetchHospitalStatisticsFailure,
  fetchHospitalAllStatisticsRequest,
  fetchHospitalAllStatisticsSuccess,
  fetchHospitalAllStatisticsFailure,
  fetchHospitalAppointmentsPriceRequest,
  fetchHospitalAppointmentsPriceSuccess,
  fetchHospitalAppointmentsPriceFailure,
} = statisticsSlice.actions;

export default statisticsSlice.reducer;
