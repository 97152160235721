import React from "react";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import fetchBaseUrl from "../store/fetchs/fetchBaseURL.json";

const baseUrl = fetchBaseUrl.API_BASE_URL;

const UploadFile = ({
  setImageUrls,
  setImageUrl,
  content,
  className = "",
  multiple = false,
}) => {
  const handleFile = async (event) => {
    const files = event.target.files;
    const uploadedUrls = [];

    for (let file of files) {
      const formData = new FormData();
      formData.append("file", file);

      try {
        const response = await fetch(`${baseUrl}/api/File/upload`, {
          method: "POST",
          body: formData,
        });

        if (response.ok) {
          const data = await response.json();
          const filePath = data.filePath;
          uploadedUrls.push(filePath);
        } else {
          toast.error("Xəta baş verdi");
        }
      } catch (error) {
        toast.error("Fayl yüklənən zaman xəta baş vardi");
        console.error("Error:", error);
      }
    }

    if (multiple) {
      setImageUrls((prevUrls) => [...prevUrls, ...uploadedUrls]);
    } else {
      setImageUrl(uploadedUrls[0]);
    }

    toast.success("Fayl yükləndi");
  };

  return (
    <Button
      variant="contained"
      component="label"
      className={`${className} app-theme-color`}
    >
      {content}
      <input type="file" hidden onChange={handleFile} multiple={multiple} />
    </Button>
  );
};

export default UploadFile;
