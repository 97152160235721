import {
    fetchCategoriesRequest,
    fetchCategoriesSuccess,
    fetchCategoriesFailure,
    createCategoryRequest,
    createCategorySuccess,
    createCategoryFailure,
    updateCategoryRequest,
    updateCategorySuccess,
    updateCategoryFailure,
    deleteCategoryRequest,
    deleteCategorySuccess,
    deleteCategoryFailure,
  } from "../reducers/categoryReducer";
  import fetchWithAuth from "./fetchWithAuth";
  import fetchBaseUrl from "./fetchBaseURL.json";
  
  const baseUrl = fetchBaseUrl.API_BASE_URL;
  
  export const fetchCategories = () => (dispatch) => {
    dispatch(fetchCategoriesRequest());
    return fetchWithAuth(`${baseUrl}/api/Category/get-categories`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        dispatch(fetchCategoriesSuccess(data));
      })
      .catch((err) => {
        dispatch(fetchCategoriesFailure(err.message));
      });
  };
  
  export const createCategory = (name) => (dispatch) => {
    dispatch(createCategoryRequest());
    return fetchWithAuth(`${baseUrl}/api/Category/create-category`, {
      method: "POST",
      body: JSON.stringify({ name }),
    })
      .then((response) => response.json())
      .then((data) => {
        dispatch(createCategorySuccess(data));
        return data;
      })
      .catch((err) => {
        dispatch(createCategoryFailure(err.message));
      });
  };
  
  export const updateCategory = (id, name) => (dispatch) => {
    dispatch(updateCategoryRequest());
    return fetchWithAuth(`${baseUrl}/api/Category/update-category/${id}`, {
      method: "PUT",
      body: JSON.stringify({ name }),
    })
      .then((response) => response.json())
      .then((data) => {
        dispatch(updateCategorySuccess(data));
      })
      .catch((err) => {
        dispatch(updateCategoryFailure(err.message));
      });
  };
  
  export const deleteCategory = (id) => (dispatch) => {
    dispatch(deleteCategoryRequest());
    return fetchWithAuth(`${baseUrl}/api/Category/delete-category/${id}`, {
      method: "DELETE",
    })
      .then((response) => response.text())
      .then(() => {
        dispatch(deleteCategorySuccess(id));
      })
      .catch((err) => {
        dispatch(deleteCategoryFailure(err.message));
      });
  };
  