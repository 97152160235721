import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchHospitals } from "../../store/fetchs/hospitalActions";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "../../assets/styles/User/Home/GeneralStatistics.css";

let GeneralStatistics = () => {
  const hospitals = useSelector((state) => state.hospital.hospitals);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(fetchHospitals());
  }, [dispatch]);

  const handleCopy = () => {
    navigator.clipboard
      .writeText("https://draynurbadavi.digicardsapp.com/")
      .then(() => {
        toast.success("link kopyalandı");
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  return (
    <>
      {hospitals.map((hospital, index) => {
        return (
          <div
            onClick={() => navigate(`/user/hospital-statistics/${hospital.id}`)}
            className="user-box-shadow home-general-statistics-hospital"
          >
            {hospital.name}
          </div>
        );
      })}
      <button className="copy-button-home" onClick={handleCopy}>
        Digicart linkini kopyala
      </button>
    </>
  );
};

export default GeneralStatistics;
