import React, { useState } from "react";
import PatientsComeFrom from "./PatientsComeFrom";
import StatisticsButtonGroup from "./StatisticsButtonGroup";
import StatisticGeneralStatistics from "./StatisticGeneralStatistics";
import StatisticsPassword from "./StatisticsPassword"; // Import the password component
import { useSelector } from "react-redux";
import StatisticsHospital from "./StatisticsHospital";
import StatisticsHospitalAppointment from "./StatisticsHospitalAppointment";
import "../../assets/styles/User/Statistic/Statistics.css";

let Statistics = () => {
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const isAuthenticated = useSelector(
    (state) => state.statistics.isAuthenticated
  );

  const handleDateChange = (range) => {
    setDateRange(range);
  };

  return (
    <div className="user-statistics-container">
      {isAuthenticated ? (
        <>
          <div className="statistics-button-group user-box-shadow">
            <StatisticsButtonGroup onDateChange={handleDateChange} />
          </div>
          <div className="statistics-general-statisics user-box-shadow">
            <StatisticGeneralStatistics dateRange={dateRange} />
          </div>
          <div className="statistics-patient-come-from user-box-shadow">
            <PatientsComeFrom dateRange={dateRange} />
          </div>
          <div className="statistics-hospitals user-box-shadow">
            <StatisticsHospital dateRange={dateRange} />
          </div>
          <div className="statistics-hospital-appointments-price user-box-shadow">
            <StatisticsHospitalAppointment dateRange={dateRange} />
          </div>
        </>
      ) : (
        <StatisticsPassword />
      )}
    </div>
  );
};

export default Statistics;
