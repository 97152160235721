import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  hospitals: [],
  loading: false,
  error: null,
};

const hospitalSlice = createSlice({
  name: "hospital",
  initialState,
  reducers: {
    fetchHospitalsRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchHospitalsSuccess: (state, action) => {
      state.loading = false;
      state.hospitals = action.payload;
    },
    fetchHospitalsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    // Create Service Reducers
    createHospitalRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    createHospitalSuccess: (state, action) => {
      state.loading = false;
      state.hospitals.push(action.payload);
    },
    createHospitalFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    // Update Service Reducers
    updateHospitalRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    updateHospitalSuccess: (state, action) => {
      state.loading = false;
      const index = state.hospitals.findIndex(service => service.id === action.payload.id);
      if (index !== -1) {
        state.hospitals[index] = action.payload;
      }
    },
    updateHospitalFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    // Delete Service Reducers
    deleteHospitalRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    deleteHospitalSuccess: (state, action) => {
      state.loading = false;
      state.hospitals = state.hospitals.filter(service => service.id !== action.payload);
    },
    deleteHospitalFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchHospitalsRequest,
  fetchHospitalsSuccess,
  fetchHospitalsFailure,
  createHospitalRequest,
  createHospitalSuccess,
  createHospitalFailure,
  updateHospitalRequest,
  updateHospitalSuccess,
  updateHospitalFailure,
  deleteHospitalRequest,
  deleteHospitalSuccess,
  deleteHospitalFailure,
} = hospitalSlice.actions;

export default hospitalSlice.reducer;
