import React, { useState } from "react";
import { TextField, Button, Box } from "@mui/material";
import { useDispatch } from "react-redux";
import { fetchCheckPassword } from "../../store/fetchs/statisticsActions";
import "../../assets/styles/User/Statistic/Statistics.css";

const StatisticsPassword = () => {
  const [password, setPassword] = useState("");
  var dispatch = useDispatch();
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handlePasswordSubmit = () => {
    dispatch(fetchCheckPassword(password));
  };

  return (
    <div className="statistics-password-container user-box-shadow">
      <Box display="flex" flexDirection="column" alignItems="center">
        <TextField
          type="password"
          label="Password"
          value={password}
          onChange={handlePasswordChange}
          size="small"
          margin="normal"
        />
        <Button
          variant="contained"
          className="app-theme-color"
          onClick={handlePasswordSubmit}
        >
          OK
        </Button>
      </Box>
    </div>
  );
};

export default StatisticsPassword;
