import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AddPatient from "../AddPatient/AddPatient";
import { fetchPatientById } from "../../store/fetchs/patientActions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useLoading } from "../../LoadingContext";
import PatientTreatments from "./PatientTreatments";
import PatientAppointments from "./PatientAppointments";

const EditPatient = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { setLoading } = useLoading();
  const patientData = useSelector((state) => state.patient.patientData);
  const [patient, setPatient] = useState({
    name: "",
    phone: "",
    birthday: null,
    gender: "",
    insurance: "",
    doctorReferral: "",
  });
  const [hospital, setHospital] = useState(null);

  const [appointments, setAppointments] = useState([]);
  const [treatments, setTreatments] = useState([]);

  useEffect(() => {
    const getPatient = async () => {
      setLoading(true);
      try {
        await dispatch(fetchPatientById(id));
      } catch (error) {
        setLoading(false);
        toast.error(error.message || "Xəta baş verdi");
      } finally {
        setLoading(false);
      }
    };

    getPatient();
  }, [dispatch, id, setLoading]);

  useEffect(() => {
    if (patientData) {
      setPatient({
        id: id,
        name: patientData.fullName,
        birthday: patientData.birthDate
          ? patientData.birthDate.split("T")[0]
          : null,
        doctorReferral: patientData.doctorReferral,
        phone: patientData.phoneNumber,
        gender:
          patientData.gender === "NotSelected" ? null : patientData.gender,
        insurance: patientData.insurance,
      });
      setHospital({
        value: patientData.hospitalId,
        label: patientData.hospitalName,
      });
      setAppointments(patientData.appointments);
      setTreatments(patientData.treatments);
    }
  }, [id, patientData]);

  return (
    <div>
      <AddPatient patient={patient} sendedHospital={hospital} />
      {treatments.length === 0 || (
        <PatientTreatments patientId={id} treatments={treatments} />
      )}
      {appointments.length === 0 || (
        <PatientAppointments appointments={appointments} />
      )}
    </div>
  );
};

export default EditPatient;
